import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <Seo lang="en" title="404: Not found | Yohan Dalpatadu" />
    <div className="bg-black h-screen">
      <div className="text-center h-full flex flex-wrap content-center justify-center">
        <div>
        <h1 className="text-white">404</h1>
          <p className="text-white text-2xl lg:text-4xl">Oops, the page you are searching for is a unicorn. <br/>We are looking for it too!</p>
          <p className="text-gray-400 text-2xl">
            <a href="/">Go back Home</a>
          </p>
        </div>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
